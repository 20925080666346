import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '@environments/environment';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { NavController } from '@ionic/angular';
import { AbstractAuthGuard } from '@services/abstract/abstract.auth.guard';
import { AuthService } from '@services/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class TermsGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected navCtrl: NavController,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.check();
  }

  protected check(): boolean {
    const acceptDate = new Date(this.authService.currentUser.generalConditions);
    const termsDate = new Date(environment.termsDate);
    if (acceptDate < termsDate) {
      this.navCtrl.navigateRoot(`/admin/accept-terms`);
      return false;
    }

    return true;
  }
}
