import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { WalletResponseInterface } from '@interfaces/user/wallet/wallet.response.interface';
import { NavController } from '@ionic/angular';
import { AuthService } from '@services/auth/auth.service';
import { WalletsService } from '@services/users/wallets/wallets.service';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sso',
  templateUrl: 'sso.component.html',
})
export class SsoComponent implements OnInit, OnDestroy {
  private subQuery: Subscription;
  private subAuth: Subscription;
  private subWallets: Subscription;
  private goto: 'BTC' | 'ETH' | 'HOME' = 'HOME';
  private user: UserResponseInterface;
  options: AnimationOptions = {
    path: '/assets/bnext-icons/App-loader.json',
  };
  pBarValue: number;
  constructor(
    private readonly authService: AuthService,
    private readonly walletsService: WalletsService,
    private readonly route: ActivatedRoute,
    private readonly navCtrl: NavController,
  ) {}

  async ngOnInit(): Promise<void> {
    this.runDeterminateProgress();
    this.subQuery = this.route.queryParams.subscribe((params: Params) =>
      this.onQuery(params),
    );
    this.subAuth = this.authService
      .getObservable()
      .pipe(filter((user: UserResponseInterface) => !!user))
      .subscribe((user: UserResponseInterface) => this.onAuth(user));

    this.subWallets = this.walletsService
      .getObservable('sso')
      .pipe(filter((wallets: WalletResponseInterface[]) => !!wallets))
      .subscribe((wallets) => this.onWallets(wallets));
  }

  private onAuth(user: UserResponseInterface) {
    this.user = user;
    if (!this.goto || this.goto === 'HOME') {
      this.navigateTo('/admin');
    } else {
      const urlParams = [this.user.id];
      this.walletsService.getAll({}, 'sso', urlParams);
    }
  }

  private onWallets(wallets: WalletResponseInterface[]) {
    let foundSymbol = false;
    // tslint:disable-next-line: forin
    for (const key in wallets) {
      const wallet = wallets[key];
      if (wallet.asset?.symbol === this.goto) {
        foundSymbol = true;
        this.navigateTo(`/admin/wallets/${wallet.id}/buy`);
        break;
      }
    }

    if (!foundSymbol) {
      this.navigateTo('/admin');
    }
  }

  private runDeterminateProgress() {
    for (let index = 0; index <= 100; index++) {
      this.setPercentBar(+index);
    }
  }
  private setPercentBar(i) {
    setTimeout(() => {
      const apc = i / 100;
      this.pBarValue = apc;
    }, 30 * i);
  }

  private onQuery(params: Params) {
    if (params.goto) {
      this.goto = params.goto;
    }
    if (params.sso) {
      this.authService.refresh(false, params.sso);
    }
  }

  navigateTo(goto) {
    this.navCtrl.navigateRoot(goto);
  }

  ngOnDestroy(): void {
    this.subQuery.unsubscribe();
    this.subWallets.unsubscribe();
    this.subAuth.unsubscribe();
  }
}
