import { countries } from '@interfaces/countries';
import { ToastOptions } from '@ionic/core';
import { BrowserOptions } from '@sentry/browser';
import { LaddaConfigArgs } from 'angular2-ladda';
import { IPartialConfigOptions } from 'ng2-ui-auth/lib/config-interfaces';

export const environment = {
  environment: 'production',
  production: true,
  static: '',
  api: 'https://lvwl9hv0vpvdurq3o3lsqmtrr7tadf.onyze.com/v1',
  client: {
    name: '59q2xdPPw',
    secret: 'onyzeRulez1!',
  },

  sentry: {
    dsn: 'https://17284691d99844f68f9ba2abdd982b3e@o307438.ingest.sentry.io/1774761',
    debug: false,
    enabled: false,
  } as BrowserOptions,
  ladda: {
    style: 'zoom-in',
    spinnerSize: 40,
    spinnerColor: 'white',
    spinnerLines: 12,
  } as LaddaConfigArgs,
  toaster: {
    showCloseButton: false,
    position: 'bottom',
    closeButtonText: 'Done',
    duration: 3000,
  } as ToastOptions,
  toastOptions: {
    mode: 'md',
    buttons: [
      {
        side: 'end',
        text: 'Cerrar',
        role: 'cancel',
      },
    ],
    duration: 3000,
  } as ToastOptions,
  storageDB: {
    name: 'onyze-wallet',
  },
  socialAuth: {} as IPartialConfigOptions,

  testnet: false,

  withdrawal: {
    fees: 0.001, // 0.1%
    min: 2,
  },

  buy: {
    fees: 0.025, // 2.5%
    feesb3x: 0.0095,
    fixCommission: 0,
    min: 15,
    max: 2000,
  },

  sell: {
    fees: 0.025, // 2.5%
    feesb3x: 0.0095,
    min: 15,
    max: 2000,
  },
  bnext: {
    xWebKey: 'Production',
    url: 'https://api.bnext.es/business/onyze/user/',
    id: 'xg8v2Ay8tVmaUZTP',
  },
  ga: 'G-6YLDW5SSEJ', //Google Analytics tracking code

  networks: {
    BTC: {
      address: 'https://live.blockcypher.com/btc/address/',
      transaction: 'https://live.blockcypher.com/btc/tx/',
      color: '#F5B300',
    },
    ETH: {
      address: 'https://etherscan.io/address/',
      transaction: 'https://etherscan.io/tx/',
      color: '#8A92B2',
    },
    XRP: {
      address: '',
      transaction: '',
      color: '#01A7DF',
    },
    LIBRA: {
      address: '',
      transaction: '',
      color: '#F5B300',
    },
  },
  assets: {
    BTC: {
      eurDecimals: '2',
      buy: '6',
      sell: '6',
      withdrawal: '8',
      balance: '8',
    },
    ETH: {
      eurDecimals: '2',
      buy: '8',
      sell: '8',
      withdrawal: '8',
      balance: '8',
    },
    ADA: {
      eurDecimals: '2',
      buy: '1',
      sell: '1',
      withdrawal: '6',
      balance: '6',
    },
    USDT: {
      eurDecimals: '2',
      buy: '2',
      sell: '2',
      withdrawal: '6',
      balance: '6',
    },
    B3X: {
      eurDecimals: '6',
      buy: '6',
      sell: '6',
      withdrawal: '6',
      balance: '6',
    },
    XRP: {
      eurDecimals: '2',
      buy: '0',
      sell: '0',
      withdrawal: '6',
      balance: '6',
    },
    SOL: {
      eurDecimals: '2',
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    DOT: {
      eurDecimals: '2',
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    DOGE: {
      eurDecimals: '2',
      buy: '0',
      sell: '0',
      withdrawal: '8',
      balance: '8',
    },
    UNI: {
      eurDecimals: '2',
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    LINK: {
      eurDecimals: '2',
      buy: '2',
      sell: '2',
      withdrawal: '8',
      balance: '8',
    },
    LTC: {
      eurDecimals: '2',
      buy: '3',
      sell: '3',
      withdrawal: '8',
      balance: '8',
    },
    BCH: {
      eurDecimals: '2',
      buy: '3',
      sell: '3',
      withdrawal: '8',
      balance: '8',
    },
    MATIC: {
      eurDecimals: '2',
      buy: '1',
      sell: '1',
      withdrawal: '8',
      balance: '8',
    },
    POL: {
      eurDecimals: '2',
      buy: '1',
      sell: '1',
      withdrawal: '8',
      balance: '8',
    },
  },
  mainAsset: 'B3X',
  countries,
  blacklist: [],
  termsDate: '2022-09-13T00:00:00.000Z',
};

environment.socialAuth = {
  baseUrl: environment.api,
  providers: {
    linkedin: {
      url: 'tokens',
      clientId: '86az306lssh0h3',
    },
    facebook: {
      url: 'tokens',
      clientId: '1999260230336024',
    },
    google: {
      url: 'tokens',
      clientId: '392164456106-3jtkjfjc6hv92o1ccjqd053ktgu5ksls',
    },
  },
};
