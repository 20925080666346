import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceInterface } from '@interfaces/device/device.model.interface';
import { DeviceQueryParamsInterface } from '@interfaces/device/device.query.params.interface';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { FCM, NotificationData } from '@ionic-native/fcm/ngx';
import { KeychainTouchId } from '@ionic-native/keychain-touch-id/ngx';
import { Platform } from '@ionic/angular';
import { AbstractService } from '@services/abstract/abstract.service';
import {
  BasicObservable,
  BasicObservableService,
} from '@services/basic-observable/basic-observable.service';
import { ErrorsService } from '@services/errors/errors.service';

@Injectable()
export class DeviceService extends AbstractService<
  DeviceInterface,
  DeviceQueryParamsInterface
> {
  protected errorsSection = 'errors.devices';
  protected endPoint = '/users/%s/devices/';
  protected device: DeviceInterface;
  protected isMobileApp = true;

  constructor(
    protected readonly http: HttpClient,
    protected readonly errorsService: ErrorsService,
    protected readonly platform: Platform,
    protected readonly deviceService: Device,
    protected readonly fcm: FCM,
    protected readonly appVersion: AppVersion,
    protected readonly basicObservableService: BasicObservableService,
    protected readonly keychainTouchId: KeychainTouchId,
  ) {
    super(http, errorsService);
    this.isMobileApp = !(
      this.platform.is('mobileweb') || this.platform.is('desktop')
    );
    if (this.isMobileApp) {
      this.onNotification();
    }
  }

  public async getDeviceInfo(): Promise<DeviceInterface> {
    if (!this.isMobileApp) {
      return null;
    }

    if (this.device) {
      return this.device;
    }

    let pushToken = await this.fcm.getToken();
    if (!pushToken) {
      pushToken = await this.fcm.onTokenRefresh().toPromise();
    }

    this.device = {
      uuid: this.deviceService.uuid,
      platforms: this.platform.platforms(),
      os: this.deviceService.platform,
      manufacturer: this.deviceService.manufacturer,
      model: this.deviceService.model,
      serial: this.deviceService.serial,
      version: this.deviceService.version,
      pushToken,
    };

    return this.device;
  }

  public async createDevice(
    section: string | string[] = 'standard',
    urlParams: string[] = [],
    url: string = null,
  ) {
    const device = await this.getDeviceInfo();
    if (!device) {
      return false;
    }

    super.create(device, section, urlParams, url);
  }

  public async subscribeToTopic(topic: string): Promise<any> {
    return this.fcm.subscribeToTopic(topic);
  }

  public async unsubscribeFromTopic(topic: string): Promise<any> {
    return this.fcm.unsubscribeFromTopic(topic);
  }

  public isMobile(): boolean {
    return this.platform.is('cordova');
  }

  public async getVersion(): Promise<string> {
    if (!this.isMobile()) {
      return null;
    }

    return this.appVersion.getVersionNumber();
  }

  // Fingerprint
  public async fingerprintAvailable(): Promise<boolean> {
    try {
      return await this.keychainTouchId.isAvailable();
    } catch (e) {
      return false;
    }
  }

  public async keychainHas(key: string): Promise<boolean> {
    try {
      return await this.keychainTouchId.has(key);
    } catch (e) {
      return false;
    }
  }

  public async keychainSave(key: string, value: string): Promise<boolean> {
    try {
      return await this.keychainTouchId.save(key, value, true);
    } catch (e) {
      return false;
    }
  }

  public async keychainGet(key: string): Promise<string> {
    try {
      return await this.keychainTouchId.verify(key, '');
    } catch (e) {
      return null;
    }
  }

  public async keychainDelete(key: string): Promise<boolean> {
    try {
      return await this.keychainTouchId.delete(key);
    } catch (e) {
      return false;
    }
  }

  public async keychain(key: string, password: string) {
    try {
      // launch an exception
      const biometryType = await this.keychainTouchId.isAvailable();
      // const hasKey = await this.keychainTouchId.has(key);
      // alert(hasKey);

      const result = await this.keychainTouchId.save(key, password, true);
      return result;
    } catch (e) {
      return false;
    }
  }

  protected onNotification() {
    this.fcm
      .onNotification()
      .subscribe((notificationData: NotificationData) => {
        const basicObservable: BasicObservable = {
          id: notificationData.type,
          payload: notificationData,
        };

        this.basicObservableService.create(
          basicObservable,
          'push-notifications',
        );
      });
  }
}
