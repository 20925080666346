import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorInterface } from '@interfaces/errors/error.interface';
import { Config, Platform } from '@ionic/angular';
import { IonicToasterService } from '@ionServices/toaster/ionic-toaster.service';
import { AuthService } from '@services/auth/auth.service';
import {
  ErrorServiceInterface,
  ErrorsService,
} from '@services/errors/errors.service';
import { Big } from 'big.js';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { vsprintf } from 'sprintf-js';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private subPushNotifications: Subscription;
  private subErrors: Subscription;

  constructor(
    private readonly config: Config,
    private readonly authService: AuthService,
    private readonly errorsService: ErrorsService,
    private readonly toasterIonic: IonicToasterService,
    private readonly platform: Platform,
  ) {}

  async ngOnInit(): Promise<void> {
    Big.DP = 10;
    Big.RM = 0;

    registerLocaleData(localeEs, 'es');

    this.subErrors = this.errorsService
      .getObservable('errors')
      .pipe(
        filter((errors) => errors?.length > 0),
        map((errors) => errors[0]),
      )
      .subscribe((error: ErrorServiceInterface) => this.onError(error));

    this.authService.loadTokens();
    await this.platform.ready();
    this.initializeApp();
  }

  async initializeApp() {
    this.config.set('backButtonIcon', '/assets/bnext-icons/goBack.svg');
  }
  protected onError(error: ErrorServiceInterface): void {
    const errors: ErrorInterface[] = error.payload?.error?.errors;

    if (!errors) {
      console.error('Something fails');
      console.error(error);
      this.toasterIonic.presentToastWithOptions(
        'La operación no ha podido ser realizada. Vuelve a intentarlo más tarde',
        'error-toastr',
      );
    } else {
      errors.forEach((singleError) => {
        // Translate
        const key = singleError.message;
        const msg = vsprintf(key, singleError.values);
        this.toasterIonic.presentToastWithOptions(msg, 'error-toastr');
      });
    }

    this.errorsService.remove(error.id);
  }

  ngOnDestroy() {}
}
