import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { environment } from '@environments/environment';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { DeviceServiceModule } from '@ionServices/device/device.module';
import { IonicToasterModule } from '@ionServices/toaster/ionic-toaster.module';
import { ActionObservableModule } from '@services/action-observable/action-observable.module';
import { AlertsServiceModule } from '@services/alerts/alerts.module';
import { AuthModule } from '@services/auth/auth.module';
import { BasicObservableModule } from '@services/basic-observable/basic-observable.module';
import { ErrorsModule } from '@services/errors/errors.module';
import { LocalStorageModule } from '@services/local-storage/local-storage.module';
import { ScheduledServiceModule } from '@services/scheduled/scheduled.module';
import { UsersServiceModule } from '@services/users/users.module';
import { WalletsServiceModule } from '@services/users/wallets/wallets.module';
import { GuardModule } from '@shared/guards/guards.module';
import { NoUserGuard } from '@shared/guards/no.user.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { LaddaModule } from 'angular2-ladda';
import player from 'lottie-web';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';
import { AppComponent } from './app';
import { SentryErrorHandler } from './sentry.error.handler';
import { SsoComponent } from './sso';

export function playerFactory() {
  return player;
}

const routes: Routes = [
  {
    path: '',
    redirectTo: '/admin',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivateChild: [UserGuard],
  },
  {
    path: 'sso',
    component: SsoComponent,
    canActivate: [NoUserGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  declarations: [AppComponent, SsoComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md' }),
    ErrorsModule.forRoot(),
    UsersServiceModule.forRoot(),
    WalletsServiceModule.forRoot(),
    AlertsServiceModule.forRoot(),
    ScheduledServiceModule.forRoot(),
    LocalStorageModule.forRoot(),
    BasicObservableModule.forRoot(),
    ActionObservableModule.forRoot(),
    IonicToasterModule.forRoot(),
    AuthModule.forRoot(),
    GuardModule.forRoot(),
    RouterModule.forRoot(routes),
    DeviceServiceModule.forRoot(),
    NgxMaskModule.forRoot(),
    LaddaModule.forRoot(environment.ladda),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    Deeplinks,
    StatusBar,
    Facebook,
    SplashScreen,
    Device,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
