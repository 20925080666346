import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '@environments/environment';
import { UserResponseInterface } from '@interfaces/user/user.response.interface';
import { NavController } from '@ionic/angular';
import { AbstractAuthGuard } from '@services/abstract/abstract.auth.guard';
import { AuthService } from '@services/auth/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdditionalUserDataGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected navCtrl: NavController,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.canActivateChild(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.check();
  }

  protected check(): boolean {
    // TODO: Comprobamos que no falta ningun dato adicional
    const user = this.authService.currentUser;

    const formKeys = [
      'professionalActivity',
      'professionalActivitySector',
      'businessRelationship',
      'sourceOfFunds',
    ];

    for (const key of formKeys) {
      if (!user.hasOwnProperty(key) || user[key] === null || !user[key]) {
        this.navCtrl.navigateRoot(`/admin/additional-data`);
        return false;
      }
    }
    return true;
  }
}
